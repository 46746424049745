<template>
	<div class="search">
		<div class="searchInput">
			<van-search v-model="value" show-action placeholder="请输入搜索关键词" @search="onSearch(value)"
				@focus="focusPrice($event)" autofocus="true">
				<template #action>
					<div @click="onSearch(value)">搜索</div>
				</template>
			</van-search>
		</div>

		<div class="lianxiang">
			<van>
				<van-list>
					<div v-for="(items, ins) in goodsLists" class="goods-items">
						<div class="listss">
							<p>{{ items.name }}</p>
						</div>
					</div>
				</van-list>
			</van>
		</div>
		<div class="listGoods" v-show="shows">
			<van>
				<van-list v-model="isUpLoading" :finished="upFinished" :immediate-check="false" :offset="10"
					@load="onLoadList" style="padding: 15px 15px 50px 15px; height: 100%">
					<div v-for="(item, index) in goodsList" class="goods-item" v-if="dataStatus">
						<div class="shopImage" @click="shopName(item)">
							<img :src="imageUrl + item.image" alt="" />
							<div class="info">
								<p style="font-size: 16px; font-weight: 600">{{ item.name }}</p>
								<p style="color: #333333; font-size: 14px">
									{{ item.address }}
								</p>
							</div>
						</div>
						<div v-for="(info, ins) in item.products.data" class="smallList" @click="proName(info)">
							<img :src="imageUrl + info.image" alt="" />
							<div class="infoList">
								<p style="font-size: 13px" class="nameINDO">{{ info.name }}</p>
								<p style="color: red">￥{{ info.price }}</p>
							</div>
						</div>
					</div>

					<div class="zanwu" v-else>
						<van-empty :image="emptyImg" description="暂无" />
					</div>
				</van-list>
			</van>
		</div>
		<div v-show="show" class="hisList">
			<div class="content" >
				<span>搜索历史</span>
				<span class="cancel" @click="clearHistory">清空</span>
			</div>
			<div class="history">
				<span class="norecord" v-if="historyList.length == 0">暂无搜索记录</span>
				<span class="record" v-else v-for="(historyItem, index) in historyList" :key="index"
					@click="onSearch(historyItem)">
					{{ historyItem }}
				</span>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		getSearch,
		getSearchTip 
	} from "@/api/index";
	import http from "@/utils/config";
	export default {
		data() {
			return {
				dataStatus: true,
				value: "",
				page: 1,
				limit: 10,
				goodsList: [], // 请求数据
				isDownLoading: false, // 下拉刷新
				isUpLoading: false, // 上拉加载
				upFinished: false, // 上拉加载完毕
				offset: 100, // 滚动条与底部距离小于 offset 时触发load事件
				imageUrl: http.imageUrl,
				smallList: [],
				goodsLists: [],
				input: "",
				historyList: [],
				show: true,
				shows:false
			};
		},
		watch: {
			// value() {
			//   console.log(this.value);
			//   let params = {
			//     name: this.value,
			//     code: this.$route.query.code,
			//     lng: this.$route.query.lng,
			//     lat: this.$route.query.lat,
			//     page: this.page,
			//     limit: this.limit,
			//   };
			//   getSearchTip(params).then((res) => {
			//     console.log(res);
			//     this.goodsLists = res.data;
			// 	this.show = false
			//     console.log(this.goodsLists);
			//   });
			// },
		},
		methods: {
			focusPrice() {
				console.log('聚焦')
			},
			// 搜索
			onSearch(val) {
				// console.log(val);
				this.setlocalHistory(val); // 将搜索值加入本地localStorage
				this.historyList = localStorage.getItem("localHistory").split("|"); // 从本地localStorage取出搜索历史并展示
				this.input = ""; // 清空输入框
				this.show = false
				this.shows = true
				let params = {
					name: val,
					code: this.$route.query.code,
					lng: this.$route.query.lng,
					lat: this.$route.query.lat,
					page: this.page,
					limit: this.limit,
				};
				getSearch(params).then((res) => {
					console.log(res);
					// this.goodsList = res.data.data
					const rows = res.data.data;
					if (rows.length === 0) {
						// 加载结束
						this.upFinished = true;
						if (this.page == 1) {
							this.dataStatus = false;
							this.$toast.clear();
						}
						return;
					} else {
						this.dataStatus = true;
					}
					if (rows.length < this.limit) {
						// 最后一页不足10条的情况
						this.upFinished = true;
					}
					// 处理数据
					if (this.page === 1) {
						this.goodsList = rows;
					} else {
						this.goodsList = this.goodsList.concat(rows);
					}
				});
			},
			setlocalHistory(val) {
				val = val.trim();
				let localHistory = localStorage.getItem("localHistory");
				if (localHistory === null) {
					localStorage.setItem("localHistory", val); // 若未设置过则直接设置本地存储
				} else {
					let localHistoryArray = localHistory
						.split("|")
						.filter((item) => item != val); // 删除搜索历史中与本次输出重复项
					if (localHistoryArray.length > 0) {
						localHistory = val + "|" + localHistoryArray.join("|"); // 新增记录
					}
					if (localHistory.split("|").length > 10) {
						// 最大历史搜索记录10条
						localHistoryArray = localHistory.split("|");
						localHistoryArray.pop(); // 删除最旧一项
						localHistory = localHistoryArray.join("|");
					}
					localStorage.setItem("localHistory", localHistory); // 存入本地
				}
			},
			clearHistory() {
				console.log(12)
				localStorage.removeItem("localHistory"); // 清空搜索历史
				this.historyList = [];
			},
			// 上拉加载请求方法
			onLoadList() {
				this.page++;
				this.onSearch();
			},
			shopName(item) {
				console.log(item);
				this.$router.push({
					path: "/shopDetail",
					query: {
						id: item.id,
					},
				});
			},
			proName(info) {
				console.log(info);
				this.$router.push({
					path: "/goodsDetail",
					query: {
						id: info.id,
					},
				});
			},
		},
		created() {},
		mounted() {
			// console.log(this.$route.query.code);
			// console.log(this.$route.query.lng);
			// console.log(this.$route.query.lat);
			// this.onSearch()
			if (localStorage.getItem("localHistory") !== null) {
				this.historyList = localStorage.getItem("localHistory").split("|");
			}
		},
	};
</script>

<style scoped lang="less">
	.search {
		// height: 100%;
		position: relative;

		.searchInput {
			width: 100%;
			// position: fixed;
			// top: 0;
			z-index: 10;
		}
		.hisList{
			margin: 0 auto;
			width: 90%;
			.content {
				// position: absolute;
				// top: 60px;
				
				font-size: 12px;
				margin-top: 10px;
			
				.cancel {
					float: right;
					color: #1f8ceb;
					cursor: pointer;
				}
			}
			.history {
				width: 100%;
				position: absolute;
				top: 100px;
				.norecord {
					font-size: 12px;
					color: #aaa;
					margin-left: 35%;
				}
			
				.record {
					display: inline-block;
					padding: 3px 10px;
					border-radius: 8px;
					background-color: #fff;
					font-size: 12px;
					color: #333;
					margin-right: 10px;
					margin-top: 5px;
					cursor: pointer;
				}
			}
		}
		
		.lianxiang {
			width: 100%;
			// height: 80%;
			position: absolute;
			top: 60px;

			.goods-items {
				background-color: #ffffff;
				padding: 15px 15px;
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-top: 8px;
				.listss {
					p {
						font-size: 14px;
					}
				}
			}
		}

		.listGoods {
			width: 100%;
			height: 80%;
			position: absolute;
			top: 40px;
			// margin-top: 40px;
			// padding: 40px 0 0 0;

			.goods-item {
				// width: 100%;
				background-color: #ffffff;
				padding: 10px 10px;
				display: flex;
				flex-direction: column;
				align-items: center;
				margin-top: 8px;

				.shopImage {
					width: 100%;
					display: flex;
					align-items: center;
					margin-bottom: 5px;

					img {
						width: 80px;
						height: 80px;
						border-radius: 8px;
					}

					.info {
						margin-left: 15px;

						p {
							// margin: 0;
						}
					}
				}

				.smallList {
					border-top: solid 1px #f2efef;
					width: 95%;
					display: flex;
					padding: 5px 0;

					img {
						width: 60px;
						height: 60px;
						border-radius: 4px;
					}

					.infoList {
						margin-left: 10px;
						p {
							margin: 0;
						}
						.nameINDO{
							display: -webkit-box;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 3;
							overflow: hidden;
						}
					}
				}
			}
		}
	}
</style>
